

































































import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'

@Component
export default class Home extends Vue {
  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
