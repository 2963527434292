








































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class GrantPowersTable extends Vue {
  @Prop({ required: true }) company: any;
  widthColumn = 20
  powers: any = [
    {
      db_value: 'lawsuits_faculty',
      value: 'pleitos_cobranzas',
      label: 'Pleitos y cobranzas',
      partners: []
    },
    {
      db_value: 'admin_faculty',
      value: 'administracion',
      label: 'Administración',
      partners: []
    },
    {
      db_value: 'domain_faculty',
      value: 'actos_dominio',
      label: 'Actos de dominio',
      partners: []
    },
    {
      db_value: 'credits_faculty',
      value: 'titulos de crédito',
      label: 'Títulos de crédito',
      partners: []
    }
  ]

  mounted () {
    this.prepareData()
  }

  async prepareData () {
    for (const power of this.powers) {
      if (!this.company.has_unique_administrator) power.partners.push({ id: 0, name: 'Consejo', checked: true, type: 'member' })
      for (const council of this.company.council) {
        const name = (council.partner) ? council.partner.user.full_name : `${council.first_names} ${council.last_names}`
        power.partners.push({ id: council.id, name, checked: council[power.db_value], type: council.council_role })
      }
    }
  }

  async calculatePercentageWidthColumn () {
    this.widthColumn = 78 / (this.company.council.length + 1)
  }

  @Action('UPDATE_STATE_LOADING') loading: any
}
