
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'
import { Doughnut, mixins } from 'vue-chartjs'
import Chart from 'chart.js'

const Props = Vue.extend({
  props: {
    chartData: {
      type: Object as () => Chart.ChartData,
      default: undefined
    }
  }
})

@Component({
  extends: Doughnut,
  mixins: [mixins.reactiveProp]
})
export default class PieChart extends Mixins(Props, Doughnut) {
  @Prop({ default: 'Distribución' }) legend!: string;

  options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    cutoutPercentage: 85,
    legend: { display: false }
  }

  async mounted () {
    await this.addPlugin({
      id: 'my-plugin',
      beforeDraw: (chart: any) => {
        const width = chart.chart.width
        const height = chart.chart.height
        const ctx = chart.chart.ctx
        ctx.restore()
        ctx.font = '14px Open Sans'
        ctx.textBaseline = 'middle'
        const text = this.legend
        const textX = Math.round((width - ctx.measureText(text).width) / 2)
        const textY = height / 2
        ctx.fillText(text, textX, textY)
        ctx.save()
      }
    })
    await this.renderChart(this.chartData, this.options)
  }
}
