import to from 'await-to-js'
import axios from '@/utils/axios-api'
import Login from '@/interfaces/Login'
import RecoveryPasswordEmail from '@/interfaces/RecoveryPasswordEmail'
import RecoveryPasswordEntry from '@/interfaces/RecoveryPasswordEntry'
import store from '@/store'
import AuthTypes from '@/store/types/AuthTypes'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class User {
  static async login (data: Login) {
    return await to(axios.post(`${URL_PHP}/v1/user/login`, data)) as any
  }

  static async getUsersByNotaryId (id: number) {
    return await to(axios.get(`${URL_PHP}/v1/users/notary/${id}`)) as any
  }

  static async store (user: any) {
    return await to(axios.post(`${URL_PHP}/v1/notary/user/register`, user))
  }

  static async updateUser (id: string, user: any) {
    return await to(axios.put(`${URL_PHP}/v1/user/${id}`, user))
  }

  static async deleteUser (id: string) {
    return await to(axios.delete(`${URL_PHP}/v1/user/${id}`))
  }

  static async profile () {
    return await to(axios.get(`${URL_PHP}/v1/notary/profile`)) as any
  }

  static async resetPasswordEmail (data: RecoveryPasswordEmail) {
    return await to(axios.post(`${URL_PHP}/v1/user/send-recovery-password`, data)) as any
  }

  static async resetPasswordEntry (data: RecoveryPasswordEntry) {
    return await to(axios.post(`${URL_PHP}/v1/user/send-recovery-password-entry`, data)) as any
  }

  static async refreshToken () {
    const [err, payload] = await to(store.getters[`AuthModule/${AuthTypes.getters.GET_PAYLOAD}`]) as any
    if (err) return [err]
    return await to(axios.post(`${URL_PHP}/v1/user/refresh`, {}, {
      headers: { authorization: `Bearer ${payload.refresh_token}` }
    })) as any
  }
}
