// import MenuItem from '@/interfaces/MenuItem'
const MenuItems: any = {
  grupo1: [
    {
      title: 'Resumen',
      slug: 'resume',
      id: 1,
      icon: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M15.7162 16.2234H8.4962" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M15.7162 12.0369H8.4962" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M11.2513 7.86011H8.49629" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" fill-rule="evenodd" clip-rule="evenodd" d="M15.9086 2.74976C15.9086 2.74976 8.23159 2.75376 8.21959 2.75376C5.45959 2.77076 3.7506 4.58676 3.7506 7.35676V16.5528C3.7506 19.3368 5.4726 21.1598 8.2566 21.1598C8.2566 21.1598 15.9326 21.1568 15.9456 21.1568C18.7056 21.1398 20.4156 19.3228 20.4156 16.5528V7.35676C20.4156 4.57276 18.6926 2.74976 15.9086 2.74976Z" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      route: 'Home',
      validation: null
    },
    {
      title: 'Solicitudes',
      slug: 'solicitudes',
      id: 2,
      icon: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" fill-rule="evenodd" clip-rule="evenodd" d="M21.4446 15.7579C21.4446 19.336 19.336 21.4446 15.7579 21.4446H7.97173C4.38444 21.4446 2.27588 19.336 2.27588 15.7579V7.9626C2.27588 4.38444 3.59031 2.27588 7.16847 2.27588H9.16749C9.88576 2.27588 10.5621 2.61406 10.9931 3.18868L11.9059 4.40269C12.3378 4.97618 13.0135 5.31406 13.7315 5.31549H16.5611C20.1484 5.31549 21.472 7.14108 21.472 10.7923L21.4446 15.7579Z" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green"d="M7.05893 14.4891H16.6524" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      
      `,
      route: 'Requests',
      validation: null
    },
    {
      title: 'Citas',
      slug: 'appointments',
      id: 3,
      icon: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M3.09264 9.40427H20.9166" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M16.4421 13.3097H16.4513" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M12.0046 13.3097H12.0139" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M7.55789 13.3097H7.56715" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M16.4421 17.1962H16.4513" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M12.0046 17.1962H12.0139" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M7.55789 17.1962H7.56715" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M16.0437 2V5.29078" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M7.9655 2V5.29078" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" fill-rule="evenodd" clip-rule="evenodd" d="M16.2383 3.57922H7.77096C4.83427 3.57922 3 5.21516 3 8.22225V17.2719C3 20.3263 4.83427 22 7.77096 22H16.229C19.175 22 21 20.3546 21 17.3475V8.22225C21.0092 5.21516 19.1842 3.57922 16.2383 3.57922Z" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      
      `,
      route: 'Appointments',
      validation: null
    },
    {
      title: 'Usuarios',
      slug: 'users',
      id: 4,
      icon: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M16.0201 10.9134C17.8411 10.9134 19.3171 9.4374 19.3171 7.6164C19.3171 5.7964 17.8411 4.3194 16.0201 4.3194" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" d="M17.5362 14.4964C18.0802 14.5334 18.6202 14.6114 19.1532 14.7294C19.8922 14.8764 20.7822 15.1794 21.0982 15.8424C21.3002 16.2674 21.3002 16.7624 21.0982 17.1874C20.7832 17.8504 19.8922 18.1534 19.1532 18.3054" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" fill-rule="evenodd" clip-rule="evenodd" d="M9.5914 15.2063C13.2814 15.2063 16.4334 15.7653 16.4334 17.9983C16.4334 20.2323 13.3014 20.8103 9.5914 20.8103C5.9014 20.8103 2.7504 20.2523 2.7504 18.0183C2.7504 15.7843 5.8814 15.2063 9.5914 15.2063Z" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="color-stroke-gray-400 hover-fill-stroke-primary-green" fill-rule="evenodd" clip-rule="evenodd" d="M9.5914 12.0188C7.1574 12.0188 5.2074 10.0678 5.2074 7.63382C5.2074 5.20082 7.1574 3.24982 9.5914 3.24982C12.0254 3.24982 13.9764 5.20082 13.9764 7.63382C13.9764 10.0678 12.0254 12.0188 9.5914 12.0188Z" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      route: 'Users',
      validation: null
    }
  ]
}

export default MenuItems
