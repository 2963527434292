


















































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import PartnersInformation from '@/components/user/PartnersInfomation.vue'
import { Action, Getter } from 'vuex-class'
import Company from '@/services/Company'
import GrantPowersTable from '@/components/user/GrantPowersTable.vue'
import ShareDistributionTable from '@/components/user/ShareDistributionTable.vue'
import CompanyTypes from '@/store/types/CompanyTypes'
import EventBus from '@/event-bus'
import RfcDetail from '@/components/user/RfcDetail.vue'
import Denominations from '@/components/user/resumen/Denominations.vue'
moment.locale('es')

@Component({
  components: { PartnersInformation, GrantPowersTable, ShareDistributionTable, RfcDetail, Denominations }
})
export default class CompanyInformation extends Vue {
  companyInformation = false
  partnersInformation = false
  shareDistribution = false
  uniqueAdministrator: any = [{}]
  president: any = [{}]
  secretary: any = [{}]
  commissary: any = [{}]
  member: any = [{}]

  created () {
    if (this.company.has_unique_administrator) {
      this.uniqueAdministrator = this.getNameForRoleInCouncil('unique_administrator')
    } else {
      this.president = this.getNameForRoleInCouncil('president')
      this.secretary = this.getNameForRoleInCouncil('secretary')
    }
    this.member = this.getNameForRoleInCouncil('member')
    this.commissary = this.getNameForRoleInCouncil('commissary')
  }

  mounted () {
    this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
      if (collapseId === 'company-information') this.companyInformation = isJustShown
      if (collapseId === 'partners-information') this.partnersInformation = isJustShown
      if (collapseId === 'share-distribution') this.shareDistribution = isJustShown
    })
  }

  get legalNameApproved () {
    const findLegalName = this.company.legal_names.find((legalName: any) => legalName.is_approved)
    return findLegalName ? findLegalName.name : '-'
  }

  get validateStageComplete () {
    return this.company.stages.findIndex((stage: any) => stage.stage_name === 'documents_approved' && stage.is_completed) === -1
  }

  getFormattedDate (createdOn: number) {
    const auxDate = moment.unix(createdOn).utc()
    return `${auxDate.format('DD')} de ${auxDate.format('MMMM')} de ${auxDate.format('YYYY')}`
  }

  getNameForRoleInCouncil (role: string) {
    return this.company.council.filter((council: any) => council.council_role === role)
  }

  async approvedDocument () {
    this.loading(true)
    const [error] = await Company.updateStageByCompanyId(this.company.id, 'documents_approved')
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    const [error2] = await Company.updateStageByCompanyId(this.company.id, 'partners_data')
    if (error2) {
      this.loading = false
      return this.$toasted.global.error(error2)
    }
    const [err] = await this.setCompanyData(this.company.id)
    if (err) return this.$toasted.global.error(err)
    await this.saveRender()
    this.$toasted.global.success({ message: 'Se aprobo el expediente con éxito' })
  }

  async saveRender () {
    this.loading(true)
    const [error] = await Company.getRenderConstitutive(this.company.id, true)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    const [error2] = await Company.endEdit(this.company.id)
    if (error2) {
      this.loading = false
      return this.$toasted.global.error(error2)
    }
    const [err] = await this.setCompanyData(this.company.id)
    this.loading(false)
    if (err) return this.$toasted.global.error(err)
    this.$toasted.global.success({ message: 'Se genero el acta renderizada con éxito' })
  }

  searchDocumentWithErrors () {
    let complete = true
    this.company.partners.map((partner: any) => {
      const complementaries = partner.civil_status === 'soltero'
        ? partner.cfi.document_url === null
          ? false
          : partner.cfi.is_approved && partner.cfi.rejection_category === null
        : (partner.cfi.document_url === null ||
          partner.id_spouse.document_url === null ||
          partner.marriage_certificate.document_url === null
          ? false
          : partner.cfi.is_approved && partner.cfi.rejection_category === null &&
            partner.id_spouse.is_approved && partner.id_spouse.rejection_category === null &&
            partner.marriage_certificate.is_approved && partner.marriage_certificate.rejection_category === null)
      if (!complementaries || partner.rfc === '' || !partner.mati_verification) complete = false
    })
    if (this.commissary.length && this.commissary[0].other_faculty !== 'Partner') {
      if (this.company.commissary_national_identity.document_url === null || !this.company.commissary_national_identity.is_approved || this.company.commissary_national_identity.rejection_category !== null) complete = false
    }
    return complete
    // return this.company.partners.filter((partner: any) => partner.documents.find((document: any) => !document.is_approved)).length > 0
  }

  openModalDocuments (document: any) {
    EventBus.$emit('open-modal', document)
  }

  previewOrDownload (document: any) {
    EventBus.$emit('open-preview-document-company', document)
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action('UPDATE_STATE_LOADING') loading: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) setCompanyData: any
}
