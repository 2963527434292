
















































































































import CompanyTypes from '@/store/types/CompanyTypes'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import RenderDocs from '@/services/RenderDocs'
import EventBus from '@/event-bus'

@Component
export default class RfcDetail extends Vue {
  urlLocalHost = 'https://render-docs-tally.herokuapp.com/static/files/'

  get partner () {
    return this.company.partners.find((partner: any) => this.company.rfc_detail && partner.id === this.company.rfc_detail.legal_representative_id)
  }

  async downloadDocument () {
    this.loading(true)
    const [error, response] = await RenderDocs.rfc({
      id: this.company.id,
      street: this.company.rfc_detail.street,
      suburb: this.company.rfc_detail.suburb,
      address: this.company.rfc_detail.address,
      outdoor_number: this.company.rfc_detail.outdoor_number,
      interior_number: this.company.rfc_detail.interior_number || '',
      zip_code: this.company.rfc_detail.zip_code,
      state: this.company.rfc_detail.state,
      country: this.company.rfc_detail.country,
      between_streets: this.company.rfc_detail.between_streets,
      references: this.company.rfc_detail.references,
      property_type: this.company.rfc_detail.property_type,
      phone: this.partner.user.phone,
      email: this.partner.user.email,
      rfc: this.partner.rfc,
      name: this.partner.user.name,
      first_names: this.partner.user.first_names,
      last_names: this.partner.user.last_names,
      birthdate: this.partner.birthdate,
      curp: this.partner.curp,
      activity: this.company.rfc_detail.activity,
      description_activity: this.company.rfc_detail.description_activity,
      group: this.company.rfc_detail.group,
      develop_activity: this.company.rfc_detail.develop_activity,
      tax_type: this.company.rfc_detail.tax_type,
      make_activity: this.company.rfc_detail.make_activity,
      business_activities: this.company.rfc_detail.business_activities
    })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    const element = document.createElement('a')
    element.href = this.urlLocalHost + response.data.url
    element.target = '_blank'
    element.click()
    element.remove()
  }

  openModalDocuments (document: any) {
    EventBus.$emit('open-modal', document)
  }

  previewOrDownload (document: any) {
    EventBus.$emit('open-preview-document-company', document)
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
