































































import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import AuthTypes from '@/store/types/AuthTypes'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/login'

@Component({
  mixins: [validationMixin],
  validations: validations
})
export default class Login extends Vue {
  email = ''
  password = ''

  async login () {
    this.UPDATE_STATE_LOADING(true)
    const [error] = await this.loginA({ email: this.email, password: this.password })
    this.UPDATE_STATE_LOADING(false)
    if (error) return this.$toasted.global.error(error)
    return this.$router.push({ name: 'Home' })
  }

  @Action('UPDATE_STATE_LOADING') UPDATE_STATE_LOADING: any
  @Action(`AuthModule/${AuthTypes.actions.LOGIN}`) loginA: any;
}
