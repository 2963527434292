import To from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Appointment {
  static async getAvailabilityByNotaryId (id: string, from: string, to: string) {
    return await To(axios.get(`${URL_PHP}/v1/notary/availability/${id}/${from}/${to}`)) as any
  }

  static async getAvailableHoursByNotaryId (id: string) {
    return await To(axios.get(`${URL_PHP}/v1/notary/available-hours/${id}`)) as any
  }

  static async store (id: string, scheduleData: any) {
    return await To(axios.post(`${URL_PHP}/v1/notary/create_all_events/${id}`, { availability: scheduleData })) as any
  }
}
