


































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { validationMixin } from 'vuelidate'
import { saveAs } from 'file-saver'
import EventBus from '@/event-bus'
// Validations
import validations from '@/validations/documents'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Documents from '@/services/Documents'
import Email from '@/services/Email'
import Company from '@/services/Company'

@Component({
  mixins: [validationMixin],
  validations: validations
})
export default class ModalApprovedOrRejectDocument extends Vue {
  showModal = false;
  approved = false;
  reject = false;
  confirmApproved = false;
  rejectedCategory = '';
  document: any = {};
  rejectionCategories = []
  commissaryName = ''

  mounted () {
    this.getRejectionCategories()
    EventBus.$on('open-modal', (document: any) => {
      this.showModal = true
      this.approved = false
      this.reject = false
      this.confirmApproved = false
      this.rejectedCategory = ''
      this.document = document
    })
  }

  get isReject () {
    return this.reject
  }

  get isApprove () {
    return this.approved
  }

  async getRejectionCategories () {
    this.loading(true)
    const [error, response] = await Documents.getRejectionCategories()
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.rejectionCategories = response.data
  }

  async approveOrReject () {
    console.error(this.document)
    if (this.document.owner_category === 'company') this.approveOrRejectDocumentCompany()
    else if (this.document.owner_category === 'partner') this.approveOrRejectDocumentPartner()
  }

  async approveOrRejectDocumentPartner () {
    this.loading(true)
    const [error] = await Documents.approvedDocument(this.document.partner.id, {
      document_type: this.document.type,
      is_approved: this.approved ? 1 : 0,
      rejection_category: this.reject ? this.rejectedCategory : 'None',
      company_id: this.document.company_id
    })
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.showModal = false
    this.$toasted.global.success({ message: 'Se actualizo el estatus del documento' })
    const [errSendEmail] = await Email.sendEmail(this.company.id, { stage_name: 'DOCUMENT_STATUS_REJECTED' })
    if (errSendEmail) this.$toasted.global.error(errSendEmail)
    const [err] = await this.setCompanyData(this.company.id)
    this.loading(false)
    if (err) this.$toasted.global.error(error)
  }

  async approveOrRejectDocumentCompany () {
    this.loading(true)
    const [error] = await Documents.approvedOrRejectDocumentCompany(this.company.id, {
      document_type: this.document.type,
      is_approved: this.approved,
      rejection_category: this.reject ? this.rejectedCategory : null
    })
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    if (this.approved && this.document.type === 'commissary_national_identity') {
      const [error2] = await Company.setCommissaryName(this.company.id, this.commissaryName)
      if (error2) {
        this.loading(false)
        return this.$toasted.global.error(error2)
      }
    }
    this.showModal = false
    this.$toasted.global.success({ message: 'Se actualizo el estatus del documento' })
    const [err] = await this.setCompanyData(this.company.id)
    this.loading(false)
    if (err) return this.$toasted.global.error(error)
  }

  async download () {
    console.error(this.document)
    this.loading(true)
    const [error, response] = await Documents.getDocument({
      entity_type: this.document.owner_category,
      identity: (this.document.owner_category === 'company') ? this.document.company_id : this.document.partner.id,
      document_type: this.document.type
    })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    const fileType = response.headers['content-type'].split('/')[1]
    const blob = new Blob([response.data])
    const name = (this.document.owner_category === 'company') ? this.document.type : `${this.document.partner.name}_${this.document.type}`
    saveAs(blob, `${name}.${fileType}`)
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) setCompanyData: any
}
