





































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import InputFile from '@/components/InputFile.vue'
import moment from 'moment'
import CompanyTypes from '@/store/types/CompanyTypes'
import PartnersDataPending from '@/components/user/PartnersDataPending.vue'
import DocumentHelper from '@/utils/documents'
import DocumentService from '@/services/Documents'
import UploadImage from '@/interfaces/UploadImage'
moment.locale('es')

@Component({
  components: { InputFile, PartnersDataPending }
})
export default class Documents extends Vue {
  fileRFC = null
  fileArticles = null
  replacaArticles = false
  replacaRFC = false
  signatureMeeting = false

  mounted () {
    this.company.digital_signature ? this.signatureMeeting = this.validateStageComplete(this.company, 'company_articles_mock_approved') : this.signatureMeeting = this.validateStageComplete(this.company, 'signature_meeting')
  }

  async download (document: any) {
    this.loading(true)
    const [error] = await DocumentHelper.download(document)
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
  }

  async uploadFile (documentType: string) {
    const data: UploadImage = {
      identity: this.company.id,
      entity_type: 'company',
      document_type: documentType,
      image: (documentType === 'articles_of_incorporation') ? this.fileArticles : this.fileRFC
    }
    this.loading(true)
    const [error] = await DocumentService.uploadDocument(data)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.reset(documentType)
    this.$toasted.global.success({ message: 'Se subio el documento con éxito' })
    const [errorUpdateDataCompany] = await this.setCompanyData(this.$route.params.company_id)
    this.loading(false)
    if (errorUpdateDataCompany) return this.$toasted.global.error(errorUpdateDataCompany)
  }

  reset (type: string) {
    if (type === 'articles_of_incorporation') {
      this.replacaArticles = false
      this.fileArticles = null
    } else {
      this.replacaRFC = false
      this.fileRFC = null
    }
  }

  validateStageComplete (company: any, stageName: string) { return company.stages.findIndex((stage: any) => stage.stage_name === stageName && stage.is_completed) !== -1 }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) setCompanyData: any
}
