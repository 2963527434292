



















































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import EventBus from '@/event-bus'
import moment from 'moment'
import ModalApprovedOrRejectDocument from '@/components/user/ModalApprovedOrRejectDocument.vue'
import { Action, Getter } from 'vuex-class'
import Documents from '@/services/Documents'
import CompanyTypes from '@/store/types/CompanyTypes'
moment.locale('es')

@Component({
  components: { ModalApprovedOrRejectDocument }
})
export default class PartnersInformation extends Vue {
  previewPDF = false
  showModal = false
  file: any = null
  documentSelected: any = null

  openModalDocuments (document: any) {
    EventBus.$emit('open-modal', document)
  }

  async previewOrDownload (document: any) {
    this.loading(true)
    this.showModal = false
    const [error, response] = await Documents.getDocument({
      entity_type: 'partner',
      identity: document.partner.id,
      document_type: document.type
    })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.documentSelected = document
    const reader = new FileReader()
    reader.readAsDataURL(new Blob([response.data]))
    reader.onload = () => {
      if (reader.result) {
        this.previewPDF = response.headers['content-type'].includes('application/pdf')
        if (typeof reader.result === 'string' && this.previewPDF) this.file = reader.result.replace('application/octet-stream', 'application/pdf')
        else this.file = reader.result
        this.showModal = true
      }
    }
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action('UPDATE_STATE_LOADING') loading: any
}
