







































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/user'
import { Action, State } from 'vuex-class'
import User from '@/services/User'
import EventBus from '@/event-bus'

@Component({
  mixins: [validationMixin],
  validations: validations.edit
})
export default class EditUser extends Vue {
  @Prop({ required: true }) user!: any

  show = false

  mounted () {
    EventBus.$on('open-modal-update-user', () => { this.show = true })
  }

  async edit () {
    this.loading(true)
    const [error] = await User.updateUser(this.user.email, this.user)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: 'Se actualizo la información con éxito' })
    this.show = false
    EventBus.$emit('updated-data-user')
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
