




































































































import { Component, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/user'
import { Action, State } from 'vuex-class'
import User from '@/services/User'
import EventBus from '@/event-bus'

@Component({
  mixins: [validationMixin],
  validations: validations.create
})
export default class CreateUser extends Vue {
  user = {
    full_name: '',
    email: '',
    first_names: '',
    last_names: '',
    phone: '',
    password: '',
    confirmPassword: ''
  }

  show = false

  mounted () {
    EventBus.$on('open-modal-create-user', () => { this.show = true })
  }

  async store () {
    this.loading(true)
    const [error] = await User.store({
      email: this.user.email,
      name: this.user.first_names,
      last_names: this.user.last_names,
      password: this.user.password,
      phone: this.user.phone
    })
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.show = false
    this.$toasted.global.success({ message: 'Se creo usuario con éxito' })
    EventBus.$emit('close-modal-user')
    EventBus.$emit('updated-data-user')
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
