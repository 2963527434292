import to from 'await-to-js'
import axios from '@/utils/axios-api'
import { DocumentsApprovedOrReject, DownloadDocument } from '@/interfaces/Documents'
import UploadImage from '@/interfaces/UploadImage'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Documents {
  static async approvedDocument (partnerId: string, data: DocumentsApprovedOrReject) {
    return await to(axios.put(`${URL_PHP}/v1/partner/validate_document/${partnerId}`, data)) as any
  }

  static async approvedOrRejectDocumentCompany (companyId: string, data: any) {
    return await to(axios.post(`${URL_PHP}/v2/approve-or-reject-document/${companyId}`, data)) as any
  }

  static async rejectDocumentCompany (companyId: string, rejectionCategory: string) {
    return await to(axios.put(`${URL_PHP}/v1/notary/rfc/company/${companyId}/reject`, { rejection_category: rejectionCategory })) as any
  }

  static async approvedDocumentCompany (companyId: string) {
    return await to(axios.put(`${URL_PHP}/v1/notary/rfc/company/${companyId}/approved`)) as any
  }

  static async getRejectionCategories () {
    return await to(axios.get(`${URL_PHP}/v1/rejection_categories`)) as any
  }

  static async getDocument (data: DownloadDocument) {
    return await to(axios.get(`${URL_PHP}/v1/image`, { params: { entity_type: data.entity_type, identity: data.identity, document_type: data.document_type }, responseType: 'arraybuffer' })) as any
  }

  static async uploadDocument (data: UploadImage) {
    const formData = new FormData()
    formData.append('entity_type', data.entity_type)
    formData.append('document_type', data.document_type)
    formData.append('image', data.image)
    formData.append('identity', data.identity)
    if (data.company_id) formData.append('company_id', data.company_id)
    if (data.is_approved) formData.append('is_approved', data.is_approved === 'yes' ? '1' : '0')
    return await to(axios.post(`${URL_PHP}/v1/image/upload`, formData)) as any
  }
}
