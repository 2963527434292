import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'

const validations = {
  create: {
    user: {
      email: { required, email },
      first_names: { required },
      last_names: { required },
      phone: { required },
      password: {
        required,
        containsOneNumber: (value: string) => /[0-9]/.test(value),
        minLength: minLength(8)
      },
      confirmPassword: {
        required,
        sameAs: sameAs('password')
      }
    }
  },
  edit: {
    user: {
      email: { required, email },
      first_names: { required },
      last_names: { required },
      phone: { required }
    }
  }
}

export default validations
