


































import { Component, Vue } from 'vue-property-decorator'
import ReturnPage from '@/components/ReturnPage.vue'
import { Action, State } from 'vuex-class'
import Appointment from '@/services/Appointment'
import moment from 'moment'

@Component({
  components: { ReturnPage }
})
export default class Appointments extends Vue {
  dataWeek = [{ label: '', labelEn: '', numberDay: 0, schedule: [{ hour: 0, enabled: false }] }]
  moment = moment

  mounted () {
    this.start()
  }

  async start () {
    this.loading(true)
    const [error, response] = await Appointment.getAvailableHoursByNotaryId(this.state.notary_id)
    this.loading(false)
    if (error) this.$toasted.global.error(error)
    this.formatDataWeek(response.data.data)
  }

  formatDataWeek (events: any) {
    const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes']
    const daysEn = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
    for (let d = 0; d < days.length; d++) {
      const schedule = []
      if (events) {
        const day = events[daysEn[d]]
        let i = 0
        for (let h = 9; h <= 17; h++) {
          if (i < day.length && day[i] === h) {
            schedule.push({ hour: h, enabled: true })
            i++
          } else {
            schedule.push({ hour: h, enabled: false })
          }
        }
      } else {
        for (let h = 9; h <= 17; h++) schedule.push({ hour: h, enabled: false })
      }
      if (d === 0) {
        this.dataWeek = [{ label: days[d], labelEn: daysEn[d], numberDay: d, schedule }]
      } else {
        this.dataWeek.push({ label: days[d], labelEn: daysEn[d], numberDay: d, schedule })
      }
    }
  }

  filterDay (schedule: any) {
    return schedule.map((day: any) => {
      if (day.enabled) { return day.hour }
    }).filter((item: any) => { return typeof item === 'number' })
  }

  async store () {
    const newDataWeek = {
      Monday: this.filterDay(this.dataWeek[0].schedule),
      Tuesday: this.filterDay(this.dataWeek[1].schedule),
      Wednesday: this.filterDay(this.dataWeek[2].schedule),
      Thursday: this.filterDay(this.dataWeek[3].schedule),
      Friday: this.filterDay(this.dataWeek[4].schedule)
    }
    this.loading(true)
    const [error, response] = await Appointment.store(this.state.notary_id, newDataWeek)
    this.loading(false)
    if (error) this.$toasted.global.error(error)
    this.$toasted.global.success({ message: 'Se actualizaron los horarios con éxito' })
    return this.$router.push({ name: 'Appointments' })
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
