import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Index from '@/views/user/Index.vue'
import Home from '@/views/user/Home.vue'
import User from '@/middlewares/User'
import Requests from '@/views/user/Requests.vue'
import Appointments from '@/views/user/Appointments.vue'
import AddSchedule from '@/views/user/AddSchedule.vue'
import Users from '@/views/user/Users.vue'
import RequestDetail from '@/views/user/RequestDetail.vue'
import Main from '@/components/user/Main.vue'
import DetailResumen from '@/views/user/DetailResumen.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    component: Index,
    children: [
      {
        path: 'resumen',
        component: Main,
        children: [
          {
            path: '',
            name: 'Home',
            component: Home,
            beforeEnter: multiguard([User.validateData])
          },
          {
            path: ':company_id/detalle',
            name: 'DetailResumen',
            component: DetailResumen,
            beforeEnter: multiguard([User.validateData])
          }
        ]
      },
      {
        path: '/solicitudes',
        component: Main,
        children: [
          {
            path: '',
            name: 'Requests',
            component: Requests,
            beforeEnter: multiguard([User.validateData])
          },
          {
            path: ':company_id/detalle',
            name: 'RequestDetail',
            component: RequestDetail,
            beforeEnter: multiguard([User.validateData])
          }
        ]
      },
      {
        path: '/citas',
        component: Main,
        children: [
          {
            path: '',
            name: 'Appointments',
            component: Appointments,
            beforeEnter: multiguard([User.validateData])
          },
          {
            path: 'AgregarHorarios',
            name: 'AddSchedule',
            component: AddSchedule,
            beforeEnter: multiguard([User.validateData])
          }
        ]
      },
      {
        path: '/usuarios',
        name: 'Users',
        component: Users,
        beforeEnter: multiguard([User.validateData])
      }
    ]
  }
]

export default routes
