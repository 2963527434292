











































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Company from '@/services/Company'
import DatePicker from 'vue2-datepicker'
import InputFile from '@/components/InputFile.vue'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/approved-or-reject-denomination'
import Documents from '@/services/Documents'
import moment from 'moment'
import UploadImage from '@/interfaces/UploadImage'
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { InputFile, DatePicker },
  mixins: [validationMixin],
  validations: validations
})
export default class Denominations extends Vue {
  type = ''
  legalNameSelected = 0
  uniqDenomination = ''
  uniqDenominationKey = ''
  file: any = ''
  authorizationDate: any = new Date()
  showModal = false
  showModal2 = false
  approved = false
  reject = false
  currentPage = 1
  perPage = 50
  moment = moment
  tableData: any = {
    fields: [
      { key: 'name', label: 'Autorización de denominación', sortable: false },
      { key: 'created_on', label: 'Fecha de solicitud', sortable: false },
      { key: 'actions', label: 'Acciones', sortable: false }
    ],
    items: []
  }

  @Watch('authorizationDate', { immediate: true, deep: true })
  changeBirthdate (currentValue: any) { this.authorizationDate = moment(currentValue, 'DD-MM-YYYY').format('DD-MM-YYYY') }

  get isApproved () { return this.type === 'approved' }

  get totalRows () { return this.tableData.items.length }

  mounted () { this.formattedDataTable() }

  formattedDataTable () {
    this.tableData.items = this.company.legal_names.map((legalName: any) => {
      if (legalName.is_approved) this.approved = true
      if (legalName.rejection_category) this.reject = true
      return {
        name: legalName.name,
        created_on: legalName.created_on,
        legal_name_id: legalName.id,
        is_approved: legalName.is_approved,
        rejection_category: legalName.rejection_category
      }
    })
  }

  getAmountFormatted (amount: number) { return amount.toString().length > 5 ? (amount / 100) : amount }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  openModal (action: string, legalNameId: number) {
    this.type = action
    this.uniqDenomination = ''
    this.authorizationDate = new Date()
    this.legalNameSelected = legalNameId
    this.showModal = true
  }

  async rejectLegalNames () {
    this.loading(true)
    const [error] = await Company.rejectLegalNames(this.company.id)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.showModal2 = false
    this.$toasted.global.success({ message: 'Se actualizo la información con éxito' })
    const [errorUpdateData] = await this.setCompanyData(this.company.id)
    this.loading(false)
    if (errorUpdateData) return this.$toasted.global.error(errorUpdateData)
    this.formattedDataTable()
  }

  async approvedOrRejectLegalName () {
    this.loading(true)
    const datetime = moment(this.authorizationDate, 'DD-MM-YYYY').unix()
    const [error] = await Company.approvedOrRejectLegalName(this.legalNameSelected, this.uniqDenomination, datetime, this.type === 'approved', this.uniqDenominationKey)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    const data: UploadImage = {
      identity: this.company.id,
      entity_type: 'company',
      document_type: 'legal_name_authorization',
      image: this.file,
      is_approved: this.type === 'approved' ? 'yes' : 'no'
    }
    const [errorUpload] = await Documents.uploadDocument(data)
    if (errorUpload) {
      this.loading(false)
      return this.$toasted.global.error(errorUpload)
    }
    this.showModal = false
    this.$toasted.global.success({ message: 'Se actualizo la información con éxito' })
    const [errorUpdateData] = await this.setCompanyData(this.company.id)
    this.loading(false)
    if (errorUpdateData) return this.$toasted.global.error(errorUpdateData)
    if (process.env.NODE_ENV === 'production') {
      window.analytics.identify(this.company.user.id, { name: this.company.user.full_name, email: this.company.user.email })
      window.analytics.track('AC_NameAuthorizedByNotary_OK', {
        user_id: this.company.user.id,
        company_name: this.company.name,
        company_id: this.company.id,
        full_name: this.company.user.full_name,
        email: this.company.user.email,
        phone: this.company.user.phone,
        legal_name: this.company.legal_name
      })
      window.analytics.reset()
    }
    this.formattedDataTable()
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) setCompanyData: any
}
