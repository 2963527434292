import to from 'await-to-js'
import axios from '@/utils/axios-api'
import { SendEmail } from '@/interfaces/Email'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Email {
  static async sendEmail (companyId: string, params: SendEmail) {
    return await to(axios.post(`${URL_PHP}/v1/notary/email/company/${companyId}/send`, params)) as any
  }
}
