




























































import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { Calendar } from 'v-calendar'
import Appointment from '@/services/Appointment'
import moment from 'moment'

@Component({
  components: { Calendar }
})
export default class Appointments extends Vue {
  month = new Date().getMonth();
  year = new Date().getFullYear();
  masks = { weekdays: 'WWW' }
  attributes: any = []
  from = moment().format('01-01-YYYY')
  to = moment().format('31-12-YYYY')
  showDetailEvent = false
  eventSelected = {}

  mounted () {
    this.getEvents()
    this.attributes = []
  }

  async getEvents () {
    this.loading(true)
    const [error, response] = await Appointment.getAvailabilityByNotaryId(this.state.notary_id, this.from, this.to)
    this.loading(false)
    if (error) this.$toasted.global.error(error)
    this.formattedData(response.data.data)
  }

  formattedData (events: any) {
    let key = 0
    for (const event of events) {
      const date = moment(event.event_timestamp)
      key++
      this.attributes.push({
        key,
        customData: {
          dateEvent: date.format('DD/MM/YYYY'),
          hourEvent: date.format('hh:mm a'),
          title: event.company,
          class: 'bg-primary text-white hover-cursor-pointer',
          ...event
        },
        dates: new Date(date.format('YYYY/MM/DD'))
      })
    }
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
