import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Generators extends Vue {
  private colors = [
    'ef9a9a',
    'f48fb1',
    'ce93d8',
    'b39ddb',
    '9fa8da',
    '90caf9',
    '4fc3f7',
    '4dd0e1',
    '4db6ac',
    'a5d6a7',
    'c5e1a5',
    'e6ee9c',
    'fff59d',
    'ffe082',
    'ffcc80',
    'ffab91',
    'bcaaa4',
    'b0bec5'
  ];

  getRandomColorHex () {
    return '#' + this.colors[this.getRandomArbitrary(0, this.colors.length - 1)]
  }

  private getRandomArbitrary (min: number, max: number) {
    return Math.round(Math.random() * (max - min) + min)
  }
}
