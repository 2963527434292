/* eslint-disable camelcase */
import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Company {
  static async getCompanies () {
    return await to(axios.get(`${URL_PHP}/v1/notary/companies`)) as any
  }

  static async getApprovedCompanies () {
    // return await to(axios.get(`${URL_PHP}/notary/approved-companies`)) as any
    return await to(axios.get(`${URL_PHP}/v1/notary/get-companies`)) as any
  }

  static async getCompaniesComplete () {
    return await to(axios.get(`${URL_PHP}/v1/notary/companies/complete`)) as any
  }

  static async getProfileCompanyById (id: string) {
    return await to(axios.get(`${URL_PHP}/v1/notary/companies/${id}/profile`)) as any
  }

  static async updateStageByCompanyId (id: string, stageName: string) {
    return await to(axios.post(`${URL_PHP}/v1/company/update_stage/${id}`, { stage_name: stageName }))
  }

  static async approvedOrRejectLegalName (id: number, uniqDenomination: string, datetime: number, isApproved: boolean, unique_document_key: string) {
    return await to(axios.put(`${URL_PHP}/v1/notary/legal-name/${id}/approved-rejected`, {
      clave_unica_denominacion: uniqDenomination,
      authorization_timestamp: datetime,
      is_approved: isApproved,
      unique_document_key: unique_document_key
    }))
  }

  static async rejectLegalNames (company: string) {
    return await to(axios.post(`${URL_PHP}/v1/company/${company}/reject-legal-names`))
  }

  static async setCommissaryName (company: string, commissary: string) {
    return await to(axios.post(`${URL_PHP}/v2/set-name-commissary/${company}`, { commissary }))
  }

  static async getRenderConstitutive (company: string, is_approved: any) {
    return await to(axios.post(`${URL_PHP}/v2/company/render-constitutive/${company}`, { is_approved })) as any
  }

  static async endEdit (company: string) {
    return await to(axios.post(`${URL_PHP}/v2/set-timer/${company}`)) as any
  }
}
