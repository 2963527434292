




























































import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import Company from '@/services/Company'
import moment from 'moment'

@Component
export default class RequestDetail extends Vue {
  search = ''
  currentPage = 1
  perPage = 12
  moment = moment
  backupData = []
  tableData: any = {
    fields: [
      { key: 'legal_name', label: 'Empresa', sortable: true },
      // { key: 'responsable', label: 'Nombre del solicitante', sortable: true },
      { key: 'created_on', label: 'Fecha de solicitud', sortable: true },
      { key: 'documents_approved', label: 'Expediente', sortable: false },
      { key: 'signature_meeting', label: 'Cita', sortable: false },
      { key: 'documents', label: 'Testimonio', sortable: false },
      { key: 'company_id', label: '' }
    ],
    items: []
  }

  get totalRows () {
    return this.tableData.items.length
  }

  mounted () {
    this.getCompanies()
  }

  async getCompanies () {
    this.loading(true)
    const [error, response] = await Company.getApprovedCompanies()
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.formattedDataTable(response.data.data)
  }

  formattedDataTable (companies: any) {
    const data = companies.map((company: any) => {
      return {
        legal_name: company.legal_name,
        created_on: company.company_created_on_timestamp,
        responsable: company.user.full_name,
        documents_approved: this.validateStageComplete(company, 'documents_approved'),
        signature_meeting: this.validateStageComplete(company, 'signature_meeting'),
        documents: company.testimony,
        company_id: company.id
      }
    })
    this.tableData.items = data
    this.backupData = data
  }

  validateStageComplete (company: any, stageName: string) { return company.stages.findIndex((stage: any) => stage.stage_name === stageName && stage.is_completed) !== -1 }

  searchCompany () { this.tableData.items = this.backupData.filter((company: any) => company.legal_name.toLowerCase().includes(this.search.toLowerCase())) }

  getDocumentCompanyByType (company: any, type: string) { return company.documents.find((document: any) => document.document_type === type) }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
