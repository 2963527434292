import to from 'await-to-js'
import axios from '@/utils/axios-api'

const BASE_URL_RENDER_DOC = 'https://render-docs-tally.herokuapp.com' // prod
// const BASE_URL_RENDER_DOC = 'https://render-docs-dev.herokuapp.com' // dev
// const BASE_URL_RENDER_DOC = 'http://localhost:3000'  // local

export default class RenderDocs {
  static async previewContitutive (data: any) {
    return await to(axios.post(`${BASE_URL_RENDER_DOC}/constitutive/render/document`, data)) as any
  }

  static async rfc (data: any) {
    return await to(axios.post(`${BASE_URL_RENDER_DOC}/rfc/render/document`, data)) as any
  }
}
