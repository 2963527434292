







































































import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import moment from 'moment'
import User from '@/services/User'
import EditUser from '@/components/user/EditUser.vue'
import EventBus from '@/event-bus'
import CreateUser from '@/components/user/CreateUser.vue'

@Component({
  components: { CreateUser, EditUser }
})
export default class Users extends Vue {
  showEdit = false
  showDelete = false
  showNew = false
  currentPage = 1
  perPage = 15
  moment = moment
  user = {
    id: 0,
    full_name: '',
    email: '',
    first_names: '',
    last_names: '',
    phone: ''
  };

  tableData: any = {
    fields: [
      { key: 'full_name', label: 'Nombre del usuario', sortable: true },
      { key: 'email', label: 'Correo electrónico', sortable: true },
      { key: 'last_sign_in_on', label: 'Último inicio de seción', sortable: false },
      { key: 'role', label: 'Nivel de acceso', sortable: false },
      { key: 'edit', label: 'Editar', sortable: false },
      { key: 'delete', label: 'Eliminar' }
    ],
    items: []
  }

  get totalRows () {
    return this.tableData.items.length
  }

  get isNewUser () {
    return false
  }

  mounted () {
    EventBus.$on('close-modal-user', () => {
      this.showEdit = false
      this.showDelete = false
    })
    EventBus.$on('updated-data-user', () => { this.all() })
    this.all()
  }

  createNewUser () {
    EventBus.$emit('open-modal-create-user')
  }

  updateUser () {
    EventBus.$emit('open-modal-update-user')
  }

  async all () {
    this.loading(true)
    const [error, response] = await User.getUsersByNotaryId(this.state.notary_id)
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.tableData.items = response.data.data
  }

  async remove () {
    this.loading(true)
    const [error] = await User.deleteUser(this.user.email)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.all()
    this.$toasted.global.success({ message: 'Se elimino usuario con éxito' })
    this.showDelete = false
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
