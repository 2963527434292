









































































































import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Elipse from '@/components/user/Elipse.vue'
import { mixins } from 'vue-class-component'
import Generators from '@/mixins/Generators'
import numeral from 'numeral'
import Operations from '@/mixins/Operations'
import PieChart from '@/components/PieChart.vue'
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { Elipse, PieChart },
  filters: {
    currency (value: number) { return numeral(value).format('0,0.00') }
  }
})
export default class ShareDistributionTable extends mixins(Generators, Operations) {
  widthColumn = 20
  shares = []
  totalShares = 0
  readyData = false
  chartdata = {
    labels: [] as Array<number>,
    datasets: [{
      label: 'Distribución',
      data: [] as Array<number>,
      backgroundColor: [] as Array<string>,
      borderWidth: 1
    }]
  }

  mounted () {
    this.readyData = false
    this.prepareData()
  }

  async prepareData () {
    if (this.company.share_series[0]) {
      this.totalShares = this.company.share_series[0].total_shares_in_series
      this.shares = await this.company.share_series[0].shares.map((share: any) => {
        const color = this.getRandomColorHex()
        this.chartdata.labels.push(
          share.partner.user !== null && (share.partner.user.full_name !== null || share.partner.user.name !== null)
            ? share.partner.user.full_name !== null
              ? share.partner.user.full_name
              : share.partner.user.name + ' ' + share.partner.user.first_names
            : share.partner.name !== null
              ? share.partner.name + ' ' + share.partner.first_names : ''
        )
        this.chartdata.datasets[0].data.push(share.number_of_shares)
        this.chartdata.datasets[0].backgroundColor.push(color)
        return { ...share, color, percentage: this.calculatePercentage(this.totalShares, share.number_of_shares) }
      })
      this.readyData = true
    }
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action('UPDATE_STATE_LOADING') loading: any
}
